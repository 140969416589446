import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import Container from "../../components/container"
import Typography from "../../components/typography"

const NotFoundPage = props => (
  <Layout {...props}>
    <SEO title="404: Not found" />
    <Section>
      <Container>
        <Typography color="secondary" variant="h1ExtraLarge">
          404
        </Typography>
        <Typography color="primary" variant="h2">
          Página não encontrada
        </Typography>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
