import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SECONDARY_LIGHT_COLOR,
  TERTIARY_COLOR,
  TERTIARY_LIGHT_COLOR,
} from "../../utils/colors"

export default {
  primary: `
    background-color: ${PRIMARY_COLOR};
  `,
  secondary: `
    background-color: ${SECONDARY_COLOR};
  `,
  "secondary-light": `
    background-color: ${SECONDARY_LIGHT_COLOR};
  `,
  tertiary: `
    background-color: ${TERTIARY_COLOR};
  `,
  "tertiary-light": `
    background-color: ${TERTIARY_LIGHT_COLOR};
  `,
  grey: `
    background-image: linear-gradient(to right, #E9EAFF 0%, #D5D5D5 45%);
    @media (max-width: 960px) {
      background-image: linear-gradient(#E9EAFF 0%, #D5D5D5 45%);
    }
  `,
}
